
@font-face {
	font-family: 'futura';
	src: url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/tt0140m.woff2') format('woff2'),
	url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/tt0140m.woff') format('woff'),
	url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/tt0140m.ttf') format('truetype');
}

@font-face {
	font-family: 'futuramed';
	src: url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/tt0142m.woff2') format('woff2'),
	url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/tt0142m.woff') format('woff'),
	url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/tt0142m.ttf') format('truetype');
}

@font-face {
	font-family: 'jpfonta';
	src: url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/jpFonta.woff2') format('woff2'),
	url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/jpFonta.woff') format('woff'),
	url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/jpFonta.ttf') format('truetype');
}

@font-face {
	font-family: 'jpfontb';
	src: url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/jpFontb.woff2') format('woff2'),
	url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/jpFontb.woff') format('woff'),
	url('https://cdn.shopify.com/s/files/1/0429/9673/t/9/assets/jpFontb.ttf') format('truetype');
}

div.homeSectionContainer{
	width:100%;display:block;margin-bottom:40px; text-align:center;float: left;vertical-align: top;margin-top: 40px;padding-top: 0px;background-color: white;
}

h1.homeSectionTitle{
max-width: 90%;margin: 20px auto;line-height: 50px;margin-bottom: 40px;
}

div.homeArticleContainer h1{
line-height: 50px !important;
}
div#callToActionInner{
	max-width:280px !important;
}
div#search .toolbar-wrapper .numbr {
	color: white !important;
}
a[href^=tel] {
	text-decoration:inherit;
	color: inherit;
}
div.homeArticleContainer{
	border-top:2px solid grey;
}

#search .search-field {
	text-align: center;
}
div#byboothster {
    display: none;
}
div#search .search_box {
	height: 40px;
	padding: 0px 60px 0 35px;
	border: none;
	vertical-align: middle;
	line-height: normal;
	color: #000000 !important;
	border: 1px solid #dddddd;
	border-radius: 5px;
}
footer .copyright p {
	text-align: center !important;
}
div#search .pagination {
	text-align: center;
	margin: 0 0 30px 0;
	padding: 0;
	margin-top: 30px;
}
div#search hr.divider {
	margin: 15px 0;
	background: #dddddd;
	height: 1px;
	border: 0;
	display: none;
}
div#search div.span11 div.search-result{
	padding: 20px 0px;
	border-bottom: 1px solid black;
}
#search > div:nth-child(1) > div > h1{
	text-align: center;
}
div#search div.row.results div.thumbnail{
	display: none;
} 
div#search .span11, div#search .span12 {
	width: 100% !important;
}
div#search {
	width: 90% !important;
	margin: 0px auto !important;
	max-width: 800px !important;
	margin-top: 40px !important;
}
.wrapper {

	margin: 0 auto;
	padding: 0;

}
.eco-full-left > p {
	font-size: 14px;
	font-weight: normal;
}
div.stepsss h4 {
	font-size: 18px !important;
	line-height: 24px !important;
}
div.stepsss {

	padding: 15px;
	border-right: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
	background-color: #ffffff;
}
.eco-full-left li {
	font-size: inherit;
	font-weight: normal;
	list-style: outside none none;
	text-align: left;
}
.eco-full-left {
	float: left;
	width: 100%;
}
.description {
	color: black;
	float: left;
	font-size: 14px;
	font-weight: 400;
	margin-right: 10px;
	padding-top: 8px;
}

.temp-graphic {
	background-color: #344e83;
	border: 3px solid #dddadd;
	float: left;
	padding: 10px;
	width: 80%;
	margin-bottom: 20px;
	display: none !important;
}
a.button{
	font-family: jpfonta !important;
}
body.page-blog div.full-req{

}
div.slideTitle a.innerLinkz,h1, h2, h3, h4, h5 {
	font-family: jpfonta !important;
	font-weight: 500;
}
div.slideText a.innerLinkz {
	font-family: jpfonta !important;
	font-weight: 300;
}
div.product-details-inner .product-options select {
	height: 41px !important;
	line-height: 30px;
}
.product-options select {
	margin-bottom: 30px;
}
div.product-details-inner .selector-wrapper {
	margin: 0px 20px;
	margin-right: 0px;
	width: auto;
	float: right;
	padding-right: 0px;
	margin-right: 0px;
	height: 50px !important;
	min-height: 50px !important;
}

form#add-item-form div.product-options div.purchase-section{
	float: right;
	margin-top:0px;
	display:block;
	right:0px;
	padding-top: 0px;
}
.product {
	position: relative;
	margin-bottom: 20px;
	float: none;
	display: inline-block;
	vertical-align: top;
}
form#add-item-form div.product-options div.select {
	display: inline-block;
	float: right;
}

.eco-video {
	background: none repeat scroll 0 0 white !important;
	float: left;
	padding: 0px 0 0 0;
	width: 100%;
	display: none;
}
.featured-bottom-collection {
	background: none repeat scroll 0 0 white !important;
	float: left;
	width: 100%;
}
div.homeArticleContainer {
	width: 100%;
	height: auto;
	min-height: 200px;
	display: block;
	border-radius: 3px;
	padding: 20px 0px;
	margin: 20px auto;
	max-width: 85%;
	text-align: center;
	padding-top:60px;
}
div.homeArticle {
	display: inline-block;
	width: 33%;
	vertical-align: top;
	padding: 0px 20px;
	height: 250px;
	overflow: hidden;
	cursor: pointer;
}
div.homeArticleImg {
	display: inline-block;
	width: 40%;
	vertical-align: top;
	float:left;
}
div.homeArticleImg img {
	width: 100%;
}
.rte img {
	height: auto;
	max-width: 200px;
}
div.homeArticleText {
	display: inline-block;
	width: 60%;
	vertical-align: top;
	float:right;
}
div.homeArticle div.homeArticleText h3.homeArticleTitle {
	font-size: 20px !important;
	margin: 0px;
	padding: 0px;
	line-height: 28px;
	text-align: left;
	overflow: hidden;
	margin-left: 20px;
	max-height: 54px;
	text-overflow: ellipsis;
	margin-bottom:10px;
}
div.homeArticle div.homeArticleText p {
	font-size: 14px !important;
	margin: 0px;
	padding: 0px;
	line-height: 20px;
	margin-left: 20px;
	text-align: left;
}
p.homeArticlep {
	font-size: 10px !important;
	margin: 0px;
	padding: 0px;
	line-height: 15px;
}

@media screen and (max-width:1100px){
	div.homeArticleText {
		display: block !important;
		width: 100% !important;
		vertical-align: top !important;
		float: none !important;
		margin-top: 20px !important;
	}
	div.homeArticle {
		display: inline-block !important;
		width: 33% !important;
		vertical-align: top !important;
		padding: 0px 20px !important;
		height: auto !important;
		overflow: hidden !important;
		cursor: pointer !important;
	}
	div.homeArticleImg {
		display: block !important;
		width: 100% !important;
		vertical-align: top !important;
		float: none !important;
		max-width: 400px !important;
	}
	div.homeArticleImg img {
		width: auto;
	}
	div.homeArticle div.homeArticleText h3.homeArticleTitle {
		font-size: 3vw !important;
		margin: 0px;
		padding: 0px;
		line-height: 4vw;
		text-align: left;
		overflow: hidden;
		max-height: unset;
		text-overflow: ellipsis;
		margin-bottom: 10px;
		height: auto;
		margin-left: 20px;
		display: none;
	}
	div.homeArticle div.homeArticleText p {
		font-size: 21px !important;
		margin: 0px;
		padding: 0px;
		line-height: 3vw !important;
		text-align: left;
		margin: 0px auto;
		margin-left:auto;
		margin-right: auto;
		max-width:400px;
	}
}

@media screen and (max-width:980px){
	
}

@media screen and (max-width:915px){
	#article .article {
		width: 100% !important;
	}
	section#content .full-width-text > p {
		color: #555;
		font-size: 30px;
		line-height: 34px;
		margin: 0 0 20px;
		text-align: center;
	}
	.wrapper, .toolbar, footer {
		width: 100%;
		text-align: center;
	}
	div.homeArticleText {
    display: inline-block !important;
    width: 60% !important;
    vertical-align: top !important;
    float: right !important;
}
	div.homeArticle div.homeArticleText p {
		font-size: 22px !important;
		margin: 0px !important;
		padding: 0px !important;
		line-height: 30px !important;
		text-align: left !important;
		margin: 0px auto !important;
		margin-left: auto !important;
		margin-right: auto !important;
		margin-bottom: 20px !important;
	}
	div.homeArticle div.homeArticleText h3.homeArticleTitle {
		font-size: 30px !important;
		margin: 0px !important;
		padding: 0px !important;
		line-height: 40px !important;
		text-align: center !important;
		overflow: hidden !important;
		max-height: unset !important;
		text-overflow: ellipsis !important;
		margin-bottom: 20px !important;
		height: auto !important;
		margin-left: auto !important;
		margin-right: auto !important;
		margin-top: 20px !important;
		display: block !important;
	}
	div.homeArticle {
		display: inline-block !important;
		width: 100% !important;
		vertical-align: top !important;
		padding: 0px !important;
		overflow: hidden !important;
		cursor: pointer !important;
		height: auto !important;
		margin: 30px auto !important;
		border-top: 2px solid grey;
    padding-top: 40px !important;
	}
	div.homeArticle.d1,div.homeArticle.d4,div.homeArticle.d7  {
		border-top: 0px solid grey;
	}
	div.homeArticleImg {
    display: inline-block !important;
    width: 40% !important;
    vertical-align: top !important;
    float: left !important;
    margin: 0px auto !important;
}
	div.homeArticleImg img {
		width: 100% !important;
		height: auto !important;
		max-width: 90% !important;
		margin: 0px auto !important;
	}	
	div.product-details-inner .selector-wrapper {
		margin-bottom: 40px;
	}
}

@media screen and (max-width:915px){

	div.homeArticleText {
    display: inline-block !important;
    width: 60% !important;
    vertical-align: top !important;
    float: right !important;
    margin-top: 0px !important;
}
	
div.homeArticle div.homeArticleText p {
    font-size: 18px !important;
    padding: 0px !important;
    line-height: 25px !important;
    text-align: left !important;
    margin: 0px auto !important;
    max-width: 100%;
}
div.homeArticleContainer {
   
    max-width: 90% !important;
    
}


}


@media screen and (max-width:768px){
	



}

@media screen and (max-width:700px){
	div.homeArticle div.homeArticleText h3.homeArticleTitle {
    font-size: 30px !important;
    margin: 0px !important;
    padding: 0px !important;
    line-height: 40px !important;
    text-align: center !important;
    overflow: hidden !important;
    max-height: unset !important;
    text-overflow: ellipsis !important;
    margin-bottom: 20px !important;
    height: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px !important;
}
	div.homeArticleImg {
    display: block !important;
    width: 100% !important;
    vertical-align: top !important;
    float: none !important;
    margin: 0px auto !important;
}
div.homeArticleText {
    display: block !important;
    width: 100% !important;
    vertical-align: top !important;
    float: none !important;
    margin-top: 0px !important;
}
div.homeArticle div.homeArticleText p {
    font-size: 20px !important;
    padding: 0px !important;
    line-height: 32px !important;
    text-align: left !important;
    margin: 0px auto !important;
    max-width: 100%;
}


}

@media screen and (max-width:525px){
	div.homeArticleContainer h1 {
    line-height: 40px !important;
    font-size: 30px !important;
}
	div.product-details-inner .selector-wrapper {
		margin-bottom: 40px;
	}
	h1.homeSectionTitle {
    max-width: 90%;
    margin: 20px auto;
    line-height: 40px;
    margin-bottom: 40px;
    font-size: 30px;
}
}

@media screen and (max-width:425px){
	div.product-details-inner .selector-wrapper {
		margin-bottom: 40px;
	}
}

@media screen and (max-width:325px){
	div.product-details-inner .selector-wrapper {
		margin-bottom: 40px;
	}
}